<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>수수료 관리</h1>
      <div class="is-right">
      </div>
    </div>
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="55px">
          <col width="150px">
          <col width="70px">
          <col width="150px">
          <col width="70px">
          <col width="150px">
          <col width="70px">
          
          <!-- <col width="660px"> -->
          <col width="150px">
          <col width="70px">
          <col width="150px">
          <col width="70px">
          <col width="230px">
          <col>
        </colgroup>
        <tr>
          <th><label>소속</label></th>
          <td>
            <input type="text" class="jh-form" :disabled="CHK_BL == true" @keyup.enter="dataTableSearch" v-model="CEN_TYPE_CD">
          </td>  
          <th><label>광고인</label></th>
          <td>
            <!-- <v-select class="jh-form" :disabled="CHK_BL == true" :items="USER_LIST" item-value="USER_ID" item-text="USER_NM" placeholder="선택" v-model="USER_NEWLIST"></v-select> -->
                    <v-autocomplete
                      class="jh-form"
                      v-model="USER_NEWLIST"
                      :items="USER_LIST"
                      no-data-text="일치하는 데이터가 없습니다."
                      item-text="USER_NM"
                      item-value="USER_ID"
                      placeholder="선택"
                      :disabled="CHK_BL == true"
                    >
                    </v-autocomplete>
          </td>  
          <th><label>상담원</label></th>
          <td>
            <!-- <v-select class="jh-form" :disabled="CHK_BL == true" :items="COUNSELOR_LIST" item-value="USER_ID" item-text="USER_NM" placeholder="선택" v-model="COUNSELOR_NEWLIST"></v-select> -->
                    <v-autocomplete
                      class="jh-form"
                      v-model="COUNSELOR_NEWLIST"
                      :items="COUNSELOR_LIST"
                      no-data-text="일치하는 데이터가 없습니다."
                      item-text="USER_NM"
                      item-value="USER_ID"
                      placeholder="선택"
                      :disabled="CHK_BL == true"
                    >
                    </v-autocomplete>
          </td>   
          <th><label>현상태</label></th>
          <td>
            <v-select class="jh-form" :items="CONTRACT_STATUS" item-value="CD" item-text="NM" placeholder="선택" v-model="CONTRACT_NEWSTATUS"></v-select>
          </td>            

          <th><label>조회유형</label></th>
          <td>
            <v-select class="jh-form" :items="SEARCH_TY" item-value="CD" item-text="NM" placeholder="선택" @change="changeTy()" v-model="SEARCH_NEWTY"></v-select>
          </td>
          <th><label>조회기간</label></th>
          <td>                        
            <div class="jh-cols">                        
              <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[0]" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
              </v-menu>
              <span class="jh-unit">~</span>                       
              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[1]" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
              </v-menu>
            </div>
          </td>
        </tr>

      </table>

      <table>
        <colgroup>
          <col width="55px">
          <col width="150px">
          <col width="70px">
          <col width="150px">
          <col width="70px">
          <col width="150px">
          <col width="70px">
          <col width="150px">
          <col width="70px">
          <col width="150px">          
          <col width="70px">
          <col width="110px">
          <col width="70px">
          <col width="150px">
          <col>
        </colgroup>        
        <tr>
          <th><label>가입방식</label></th>
          <td>
            <v-select class="jh-form" :items="CONTRACT_TYP" item-value="CD" item-text="NM" placeholder="선택" v-model="CONTRACT_NEWTYP"></v-select>
          </td>  
          <th><label>보험사</label></th>
          <td>
            <v-select class="jh-form" :items="INSURE_CO" item-value="CD" item-text="NM" placeholder="선택" v-model="INSURE_NEWCO"></v-select>
          </td>  
          <th><label>보험종목</label></th>
          <td>
            <v-select class="jh-form" :items="INSURE_TYP" item-value="CD" item-text="NM" placeholder="선택" v-model="INSURE_NEWTYP"></v-select>
          </td>  
          <!-- <th><label>현상태</label></th>
          <td>
            <v-select class="jh-form" :items="CONTRACT_STATUS" item-value="CD" item-text="NM" placeholder="선택" v-model="CONTRACT_NEWSTATUS"></v-select>
          </td>   -->
          <th><label>갱신상태</label></th>
          <td>
            <v-select class="jh-form" :items="RENEWAL_STATUS_LIST" item-value="CD" item-text="NM" placeholder="선택" v-model="RENEWAL_STATUS"></v-select>
          </td>     
          <th><label style="color:red;">이체상태</label></th>
          <td>
            <v-select class="jh-form" :items="TRANSFER_STATUS_LIST" item-value="CD" item-text="NM" placeholder="선택" v-model="TRANSFER_STATUS"></v-select>
          </td>                      
          <th><label>검색조건</label></th>
          <td>
            <v-select class="jh-form" :items="MAIN_SEARCH_TY" item-value="CD" item-text="NM" placeholder="선택" v-model="MAIN_SEARCH_NEWTY"></v-select>
          </td> 
          <th><label>검색어</label></th>
          <td>
            <input type="text" class="jh-form" @keyup.enter="dataTableSearch" v-model="MAIN_SEARCH_KEY">
          </td>      
          <td class="has-search"><v-btn class="jh-btn is-search" @click="dataTableSearch">조회</v-btn></td>
        </tr>
      </table>
    </div>

    <div class="jh-form-wrap" style="height: 700px;">
      <div class="jh-ui-header">
          <h2>수수료 관리 목록</h2>
          <div class="is-right">
              <v-btn class="jh-btn is-light" @click="downloadExcel('cnslHistTable', '수수료 목록', '수수료 목록')">엑셀다운로드</v-btn>
              <!-- <v-btn class="jh-btn is-border-blue" :disabled="CHK_BL == true" @click="registNew">신규</v-btn>
              <v-btn class="jh-btn is-del" :disabled="CHK_BL == true" @click="submitDeleteConfirm" >삭제</v-btn> -->
          </div>
      </div>

      <data-tables
        ref="dataTable"
        id="cnslHistTable"
        dataTableClass="has-control"
        :data-table-options="dataTableOptions"
        :paginationOptions="paginationOptions"
        @click:row="onClickRow"
        @dblclick:row="onDbclickRow"
        
      >
        <template v-slot:item.USER_NM={item}>
          <div>{{item.USER_NM }}
          <button type="button" class="jh-btn is-icon" :title="item.USER_NM" @click.stop="userDetail(item)"><i class="jh-icon-edit is-blue"></i></button>
          </div>
        </template>
      </data-tables>

    </div>
    


  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import axios from "axios";
import api from "@/store/apiUtil.js";
import apiChat from "../../store/apiChatParamUtil.js";
import { mapGetters } from "vuex";
import dataTables from "@/components/DataTables";
import DialogP101801 from "./P101801.vue";

export default {
  name: "MENU_E999990", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogP101801,
    dataTables
  },
  data() {
    return {

      selectedRow: [], //선택된 로우
      ROW_DATA:{},



      CHK_BL: false,
      MAIN_SEARCH_KEY:"",
      CEN_TYPE_CD:"",
      COUNSELOR_LIST:[],
      COUNSELOR_NEWLIST:"",
      USER_NEWLIST:"",
      USER_LIST:[],

      SEARCH_NEWTY: "INIT_DTTM",
      SEARCH_TY: [
        {
          CD: "INIT_DTTM",
          NM: "가입일"
        },
      ],


      CONTRACT_NEWTYP: "",
        CONTRACT_TYP:[
            {
              CD: "",
              NM: "전체",
            },
            {
            CD: "CM",
            NM: "CM"
            },
            {
            CD: "TM",
            NM: "TM",
            },
            {
            CD: "OFF",
            NM: "오프",
            },            
            {
            CD: "PUBLIC",
            NM: "공동"
            },
            {
            CD: "SHORTTERM",
            NM: "단기",
            },
            {
            CD: "DCM",
            NM: "CM타점"
            },
            {
            CD: "DTM",
            NM: "TM타점",
            },                
        ],    
// 현대/ DB/ KB/ 한화/ 흥국/ AXA/ 캐롯/ 하나/ 메리츠/ 삼성/ 롯데/ MG
      INSURE_NEWCO: "",
      INSURE_CO: [
        {
          CD: "",
          NM: "전체",
        },        
        {
          CD: "HD",
          NM: "현대"
        },
        {
          CD: "DB",
          NM: "DB",
        },
        {
          CD: "KB",
          NM: "KB"
        },
        {
          CD: "HH",
          NM: "한화",
        },
        {
          CD: "HK",
          NM: "흥국"
        },
        {
          CD: "AXA",
          NM: "AXA",
        },              
        {
          CD: "CR",
          NM: "캐롯"
        },
        {
          CD: "HN",
          NM: "하나",
        },
        {
          CD: "MZ",
          NM: "메리츠"
        },
        {
          CD: "SS",
          NM: "삼성",
        },
        {
          CD: "LT",
          NM: "롯데"
        },
        {
          CD: "MG",
          NM: "MG",
        },           
      ],      


      // 개인용/ 업무용/ 영업용/ 이륜차
      INSURE_NEWTYP: "",
      INSURE_TYP: [
        {
          CD: "",
          NM: "전체",
        },        
        {
          CD: "INDIVIDUAL",
          NM: "개인용"
        },
        {
          CD: "WORK",
          NM: "업무용",
        },
        {
          CD: "SALES",
          NM: "영업용"
        },
        {
          CD: "BIKE",
          NM: "이륜차",
        },
      ],            


      CONTRACT_NEWSTATUS: "",
      CONTRACT_STATUS: [
        {
          CD: "",
          NM: "전체",
        },        
        {
          CD: "NORMAL",
          NM: "정상"
        },
        {
          CD: "TERMINATION",
          NM: "해지",
        },
      ],      

      MAIN_SEARCH_NEWTY: "CONTRACTOR",
      MAIN_SEARCH_TY: [
        {
          CD: "CONTRACTOR",
          NM: "계약자",
        },        
        {
          CD: "INSURED_PERSON",
          NM: "피보험자"
        },
        {
          CD: "CAR_NUM",
          NM: "차량번호",
        },
        {
          CD: "HP_NUM",
          NM: "연락처",
        },
        {
          CD: "SSN",
          NM: "주민번호",
        },        
        {
          CD: "CNT_NUM",
          NM: "계약번호",
        },     
        {
          CD: "JISA",
          NM: "지사",
        }, 
        {
          CD: "JISUM",
          NM: "지점",
        },                      
      ],      


        TRANSFER_STATUS_LIST: [
            {
            CD: "",
            NM: "전체",
            },  
            {
            CD: "UNTRANSFER",
            NM: "미이체"
            },
            {
            CD: "TRANSFER",
            NM: "이체완료"
            },
            {
            CD: "RESERVATION",
            NM: "예약이체등록",
            },
            {
            CD: "HOLD",
            NM: "이체보류",
            },            
        ],     

        RENEWAL_STATUS_LIST: [
            {
            CD: "",
            NM: "전체",
            },  
            {
            CD: "COMPLETE",
            NM: "가입"
            },
            {
            CD: "UNCOMPLETE",
            NM: "미가입"
            },
            {
            CD: "UNESTIMATE",
            NM: "미견적",
            },
            {
            CD: "ESTIMATE",
            NM: "견적",
            },            
        ],      
        RENEWAL_STATUS:"",
        TRANSFER_STATUS:"",

      DialogP101801: false,
      menuStartDate: false,
      menuEndDate: false,
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      CURR_DT : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      talkQst: "",
      cnslrsltcd: "",
      srhCnslRsltCd: [
        { CD_NM: '전체', CD: '' },
      ],

      selected_channel: "001",
      asp_new_cust_Key: this.$store.getters["userStore/GE_USER_ROLE"].company,


      userId: "",
      userNm: "",
      userNick: "",


      gridDataHeaders: [
        { text: "NO", value: "ROWNUM", align: "center", width: "40px", sortable: false, },
        { text: "가입일", value: "INIT_DTTM", align: "center", width: "200px", },                                //체크
        { text: "보험사", value: "INSURE_CO", align: "center", width: "150px", },
        { text: "피보험자", value: "INSURED_PERSON", align: "center",  width: "150px", },
        { text: "계약자", value: "CONTRACTOR", align: "center", width: "150px", },
        { text: "가입방식", value: "CONTRACT_TYP", align: "center", width: "150px", },
        { text: "계약번호", value: "CONTRACT_NUM", align: "center", width: "150px", },
        { text: "차량번호", value: "CAR_NUM", align: "center", width: "200px", },
        { text: "차량명", value: "CAR_NM", align: "center", width: "200px", },
        { text: "보험료", value: "INSURE_FEE", align: "center", width: "200px", },

        { text: "소속", value: "CEN_TYPE_CD", align: "center", width: "200px", },
        { text: "광고인", value: "USER_NM", align: "center", width: "200px", },
        { text: "핸드폰번호", value: "HP_NO", align: "center", width: "200px", },
        { text: "은행명", value: "USER_BK", align: "center", width: "200px", },
        { text: "계좌번호", value: "USER_ACT", align: "center", width: "200px", },
        { text: "주민번호", value: "USER_SSN", align: "center", width: "200px", },
        { text: "광고료", value: "AD_FEE", align: "center", width: "200px", },
        { text: "이체상태", value: "TRANSFER_STATUS", align: "center", width: "200px", },
        { text: "비고", value: "MEMO", align: "center", width: "200px", },

      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      totalVisible: 10,
      pagination: { page: 1, rowsPerPage: 200, sortBy: "", descending: "" },
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",
      height: "50px",
      // dataTableOptions: {
      //   fixedHeader: true,
      //   hideDefaultFooter: true,
      //   height: '600px',
      //   items: [],
      //   itemKey: 'index',
      //   itemsPerPage: 50,
      //   noDataText: "조회된 결과가 없습니다.",
      //   page: 1,
      //   // showSelect: true,
      //     singleSelect: true,
      //     disableSort: true,        
      //     // itemClass: 'isActiveRow',
      // },

            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '620px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 200,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                // showSelect: true,
                singleSelect: true,
                itemClass: "[item.selected && 'selected']",
                load: undefined,
            },      

      paginationOptions: {
          totalVisible: 10
      },
    };
  },
  computed: {
        rowNum() {
            return this.items?.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        },
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },      
  },  
  async created() { 
    this.dataTableOptions.headers = this.gridDataHeaders;
    // this.dataTableOptions.items = this.gridDataText;
    this.dataTableOptions.items = this.rowNum;

  },
  async mounted() {

    this.CONTRACT_NEWTYP = this.CONTRACT_TYP[0].CD
    this.INSURE_NEWCO = this.INSURE_CO[0].CD
    this.INSURE_NEWTYP = this.INSURE_TYP[0].CD

    this.COUNSELOR_LIST = await this.mixin_get_user("20220826113735368TWBJfPKh");    
    this.USER_LIST = await this.mixin_get_user("20220826113539330TWBaSY4N");  


    this.COUNSELOR_LIST.splice(0, 0, {USER_ID: '', USER_NM: '전체'});
    this.USER_LIST.splice(0, 0, {USER_ID: '', USER_NM: '전체'});


    this.COUNSELOR_NEWLIST = this.COUNSELOR_LIST[0].USER_ID
    this.USER_NEWLIST = this.USER_LIST[0].USER_ID


    let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;   
    if(chkGroup.indexOf("관리자") > -1 || chkGroup.indexOf("매니저") > -1/*chkGroup == "이베스트관리자" || chkGroup == "시스템관리자"*/){
      this.SEARCH_TY.push({
          CD: "INIT_DTTM",
          NM: "가입일"
        },)
    }else{
      this.CHK_BL = true;
    }    
    this.dataTableSearch()
    this.changeTy()    
  },

  methods: {


      // getListSel(item, row) {
      //   console.log("18 item : ", item)
      //   console.log("18 row : ", row)
      //   if(item != null){
      //     this.activeRow(row,true);
      //     // this.selectedRow = row;
      //     // row.select(Boolean(value));
      //     // row.isSelected = Boolean(value);          
      //   }
      // },
      // async activeRow(row, value){
      //   console.log("19 row : ", row)
      //   console.log("19 value : ", value)

      //     // this.selectedRow = row;
      //     row.select(Boolean(value));
      //     // row.isSelected = Boolean(value);  
      // },      

    // onClickRow(item, row) {
    //     console.log("18 item : ", item)
    //     console.log("18 row : ", row)      
    //   if (this.selectedRow != "") {
    //     console.log("개새끼야 : ", item)
    //     this.activeRow(this.selectedRow, false);
    //     this.selectedRow = [];
    //   }
    //   this.activeRow(row, true);
    // },

    
    onClickRow(item, row) {
      // this.ROW_DATA = item;
      console.log("item",item);
      // console.log("row",row);
      this.selectedRow = row;
      // console.log("this.selectedRow",this.selectedRow);

      // this.selectedRow.select(Boolean(true))
      row.select(Boolean(true));
      row.isSelected = Boolean(true);  
      // this.selectedRow.push(row);
    },
    onDbclickRow(item, row){
  
      var ACN_USER_SSN = this.encryptAES_ECB(row.item.CONTRACT_NUM, process.env.VUE_APP_SSN_KEY);

      // window.open("E030101P01?agentId="+Math.random() * 40, 'WebPlay', 'width=780,height=360');
      window.open('E999989P01?mode=update&ctr='+ACN_USER_SSN,'_blank','width=1100,height=980');

    },       
    
    userDetail(item, row){
  
      var ACN_USER_SSN = this.encryptAES_ECB(item.USER_ID, process.env.VUE_APP_SSN_KEY);

      // window.open("E030101P01?agentId="+Math.random() * 40, 'WebPlay', 'width=780,height=360');
      window.open('E999996P01?ctr='+ACN_USER_SSN,'_blank','width=400,height=480');

    },     
    registNew(){
      window.open('E999993P01?mode=insert','insertt','width=1100,height=980');

    },    
             



        changeTy(){
          let today = new Date();   

          let year = today.getFullYear(); // 년도
          let month = today.getMonth();  // 월
          let date = today.getDate();  // 날짜
          let day = today.getDay();  // 요일

          month = month + 1
          let toMonth = month
          let fromDate = date - 1
          if(fromDate == 0){
            console.log("toMonth :11v ", toMonth)
          
            if(toMonth == 1){
              toMonth = 12
            }else{
              toMonth = toMonth - 1
            }

            if(toMonth == 1 || toMonth == 3 || toMonth == 5
            || toMonth == 7 || toMonth == 8 || toMonth == 10 || toMonth == 12){
              fromDate = 31;
            }else if(toMonth == 2){
              if(year%4 == 0){
                fromDate = 29;
              }else{
                fromDate = 28;
              }
            }else{
              fromDate = 30;
            }


          }
          if(month < 10) month = "0" + month
          if(toMonth < 10) toMonth = "0" + toMonth
          if(date < 10) date = "0" + date
          if(fromDate < 10) fromDate = "0" + fromDate
          
          if(this.SEARCH_NEWTY == "END_DTTM"){
            // month = month + 1
            this.dates[0] = year + '-' + month + '-' + date
          
          }else if(this.SEARCH_NEWTY == "START_DTTM"){
            // month = month + 1
            year = year - 1
            this.dates[0] = year + '-' + month + '-' + date

          }else if(this.SEARCH_NEWTY == "INIT_DTTM"){
            // month = month + 1
            console.log("toMonth :v ", toMonth)
            this.dates[0] = year + '-' + toMonth + '-' + fromDate
            this.dates[1] = year + '-' + month + '-' + date
            // if(toMonth > 12){
            //   toMonth = toMonth - 12
            //   if(toMonth < 10) toMonth = "0" + toMonth

            //   year = year + 1
            //   this.dates[1] = year + '-' + toMonth + '-' + date
            // }else{
            //   this.dates[1] = year + '-' + toMonth + '-' + date
            // }
          }
          console.log("this.dates[0]  ", this.dates[0])


        },



        dataTableSearch() {
            this.detailObj = {};
            this.dataTableOptions.load = this.selectContractList;

            if (this.dataTableOptions.page > 1) {
                this.dataTableOptions.page = 1;
            } else {
                this.dataTableInstance.loadData();
            }
        },


        async selectContractList(loadOptions) {
            // this.selectedRow = undefined;
            // if (this.selectedRow.length > 0) {
            //     this.selectedRow.forEach(item => {
            //         item.select(false);
            //     });
            // }

            let requestData = {
                headers: {
                    SERVICE: "easydirect.fee.list",
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',
                    ROW_CNT: "200",
                    PAGES_CNT: "1",                    
                },
                sendData: {}
            };

            const sortBy = loadOptions?.sortBy?.[0];
            const sortDesc = loadOptions?.sortDesc?.[0] === undefined ? 'desc' : loadOptions?.sortDesc?.[0] ? 'desc' : 'asc';

            requestData.headers["URL"] = "/api/easydirect/fee/list";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            // requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            // requestData.headers["PAGES_CNT"] = loadOptions.page; 

            requestData.sendData["SEARCH_FROM"] = this.dates[0];
            requestData.sendData["SEARCH_TO"] = this.dates[1];
            requestData.sendData["CEN_TYPE_CD"] = this.CEN_TYPE_CD;
            requestData.sendData["COUNSELOR_NEWLIST"] = this.COUNSELOR_NEWLIST;
            requestData.sendData["USER_NEWLIST"] = this.USER_NEWLIST;
            requestData.sendData["SEARCH_NEWTY"] = this.SEARCH_NEWTY;
            requestData.sendData["CONTRACT_NEWTYP"] = this.CONTRACT_NEWTYP;
            requestData.sendData["INSURE_NEWCO"] = this.INSURE_NEWCO;
            requestData.sendData["INSURE_NEWTYP"] = this.INSURE_NEWTYP;
            requestData.sendData["CONTRACT_NEWSTATUS"] = this.CONTRACT_NEWSTATUS;
            requestData.sendData["RENEWAL_STATUS"] = this.RENEWAL_STATUS;
            requestData.sendData["TRANSFER_STATUS"] = this.TRANSFER_STATUS;
            requestData.sendData["MAIN_SEARCH_NEWTY"] = this.MAIN_SEARCH_NEWTY;
            requestData.sendData["MAIN_SEARCH_KEY"] = this.MAIN_SEARCH_KEY;
            requestData.sendData["SORT_ORDR"] = sortBy ? sortBy : 'ROWNUM';
            requestData.sendData["SORT_DRCT"] = sortDesc;

            if(this.CHK_BL == true){
              requestData.sendData["USER_NEWLIST"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
            }else{
              requestData.sendData["USER_NEWLIST"] = this.USER_NEWLIST;
            }

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                let header = response.HEADER;

                for(var i = 0; i < response.DATA.length; i ++){
                  if(response.DATA[i].INSURE_CO == "HD"){
                    response.DATA[i].INSURE_CO = "현대"
                  }else if(response.DATA[i].INSURE_CO == "DB"){
                    response.DATA[i].INSURE_CO = "DB"
                  }else if(response.DATA[i].INSURE_CO == "KB"){
                    response.DATA[i].INSURE_CO = "KB"
                  }else if(response.DATA[i].INSURE_CO == "HH"){
                    response.DATA[i].INSURE_CO = "한화"
                  }else if(response.DATA[i].INSURE_CO == "HK"){
                    response.DATA[i].INSURE_CO = "흥국"
                  }else if(response.DATA[i].INSURE_CO == "AXA"){
                    response.DATA[i].INSURE_CO = "AXA"
                  }else if(response.DATA[i].INSURE_CO == "CR"){
                    response.DATA[i].INSURE_CO = "캐롯"
                  }else if(response.DATA[i].INSURE_CO == "HN"){
                    response.DATA[i].INSURE_CO = "하나"
                  }else if(response.DATA[i].INSURE_CO == "MZ"){
                    response.DATA[i].INSURE_CO = "메리츠"
                  }else if(response.DATA[i].INSURE_CO == "SS"){
                    response.DATA[i].INSURE_CO = "삼성"
                  }else if(response.DATA[i].INSURE_CO == "LT"){
                    response.DATA[i].INSURE_CO = "롯데"
                  }else if(response.DATA[i].INSURE_CO == "MG"){
                    response.DATA[i].INSURE_CO = "MG"
                  }

                  if(response.DATA[i].CONTRACT_STATUS == "NORMAL"){
                    response.DATA[i].CONTRACT_STATUS = "정상"
                  }else if(response.DATA[i].CONTRACT_STATUS == "TERMINATION"){
                    response.DATA[i].CONTRACT_STATUS = "해지"
                  }
                  

                  
                  if(response.DATA[i].CONTRACT_TYP == "CM"){
                    response.DATA[i].CONTRACT_TYP = "CM"
                  }else if(response.DATA[i].CONTRACT_TYP == "TM"){
                    response.DATA[i].CONTRACT_TYP = "TM"
                  }else if(response.DATA[i].CONTRACT_TYP == "OFF"){
                    response.DATA[i].CONTRACT_TYP = "오프"
                  }else if(response.DATA[i].CONTRACT_TYP == "PUBLIC"){
                    response.DATA[i].CONTRACT_TYP = "공동"
                  }else if(response.DATA[i].CONTRACT_TYP == "SHORTTERM"){
                    response.DATA[i].CONTRACT_TYP = "단기"
                  }else if(response.DATA[i].CONTRACT_TYP == "DCM"){
                    response.DATA[i].CONTRACT_TYP = "CM타점"
                  }else if(response.DATA[i].CONTRACT_TYP == "DTM"){
                    response.DATA[i].CONTRACT_TYP = "TM타점"
                  }else if(response.DATA[i].CONTRACT_TYP == "TERMINATION"){
                    response.DATA[i].CONTRACT_TYP = "해지"
                  }
                                    
                  if(response.DATA[i].RENEWAL_STATUS == "COMPLETE"){
                    response.DATA[i].RENEWAL_STATUS = "갱신완료"
                  }else if(response.DATA[i].RENEWAL_STATUS == "UNCOMPLETE"){
                    response.DATA[i].RENEWAL_STATUS = "미가입"
                  }                  
                  else if(response.DATA[i].RENEWAL_STATUS == "UNESTIMATE"){
                    response.DATA[i].RENEWAL_STATUS = "미견적"
                  }                  
                  else if(response.DATA[i].RENEWAL_STATUS == "ESTIMATE"){
                    response.DATA[i].RENEWAL_STATUS = "견적"
                  }              
                  
                  
                  if(response.DATA[i].TRANSFER_STATUS == "UNTRANSFER"){
                    response.DATA[i].TRANSFER_STATUS = "미이체"
                  }else if(response.DATA[i].TRANSFER_STATUS == "TRANSFER"){
                    response.DATA[i].TRANSFER_STATUS = "이체완료"
                  }                  
                  else if(response.DATA[i].TRANSFER_STATUS == "RESERVATION"){
                    response.DATA[i].TRANSFER_STATUS = "예약이체등록"
                  }                  
                  else if(response.DATA[i].TRANSFER_STATUS == "HOLD"){
                    response.DATA[i].TRANSFER_STATUS = "이체보류"
                  }                            

                    response.DATA[i].USER_SSN = response.DATA[i].USER_SSN.substr(0, 6).concat("-").concat(response.DATA[i].USER_SSN.substr(6))

                  response.DATA[i].INSURE_FEE = Number(response.DATA[i].INSURE_FEE).toLocaleString('ko-KR');
                  response.DATA[i].AD_FEE = Number(response.DATA[i].AD_FEE).toLocaleString('ko-KR');
                  response.DATA[i].INIT_DTTM = response.DATA[i].INIT_DTTM.substr(0, 10)                  

                }
                let data = response.DATA;



                return {
                    data: data,
                    totalCount: header.COUNT
                };
            }
            // if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                // let header = response.HEADER;
                // let data = response.DATA;
                // this.gridDataText = response.DATA
                // // this.dataTableOptions.items = this.gridDataText;
                // console.log(data)

                // data.forEach(item => {
                //     item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
                //         this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
                //     item.RESV_TEL_NO = item.RESV_TEL_NO.indexOf('-') < 0 ?
                //         this.mixin_telNumFormat(item.RESV_TEL_NO) : item.RESV_TEL_NO;
                // });

                // return {
                //     data: data,
                //     totalCount: header.TOT_COUNT
                // };
            // }
        },
      submitDeleteConfirm() {
        this.common_confirm("삭제 하시겠습니까?", async () => {
          const CONTRACT_NUM = this.selectedRow.item.CONTRACT_NUM;
          console.log("CONTRACT_NUM", CONTRACT_NUM)

          let requestData = {
            headers: {
                    SERVICE: "easydirect.list.delete",
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',              
            },
            sendData: {}
          }

          requestData.headers["URL"] = "/api/easydirect/list/delete";
          requestData.headers["METHOD"] = "delete";
          requestData.headers["ASYNC"] = false;

          requestData.sendData["CONTRACT_NUM"] = CONTRACT_NUM;

          const response = await this.common_postCall(requestData);

          if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
            this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
            return false;
          } else {
            this.common_alert(`정상처리 되었습니다.`);
            this.dataTableInstance.loadData();
          }
        });
        
      },   























    changeDate(type) {
      // if(
      //   moment(this.dates[0]).isBefore(moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD')) ||
      //   moment(this.dates[1]).isAfter(moment(new Date()).subtract(0, 'days').format('YYYY-MM-DD'))
      // ){
      //   this.common_alert('검색 기간은 최근 1개월까지 선택 가능합니다.');
      //   const date = moment().format("YYYY-MM-DD");
      //   this.dates[0] = date;
      //   this.dates[1] = date;
      //   this.from = date;
      //   this.to = date;
      //   return;
      // }

      if(type == 'start') {
        // if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(30, 'days').format('YYYY-MM-DD'))) {
        //   this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
        //   const date = moment().format("YYYY-MM-DD");
        //   this.dates[0] = date;
        //   this.dates[1] = date;
        //   this.from = date;
        //   this.to = date;
        //   return;
        // } else {
          this.from = this.dates[0];
        // }
      } else if (type == 'end') {
        // if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(30, 'days').format('YYYY-MM-DD'))) {
        //   this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
        //   const date = moment().format("YYYY-MM-DD");
        //   this.dates[0] = date;
        //   this.dates[1] = date;
        //   this.from = date;
        //   this.to = date;
        //   return;
        // } else {
          this.to = this.dates[1];
        // }
      }
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    //년월로 변환
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == "") return "";

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      // 최종 포맷 (ex - '2021-10-08')
      return year + "" + month + "" + day;
    },

    yyyyMMddHHMISS(yyyyMMddHHMISS) {
      //접수시간 포맷
      var newTime = "";
      if (!yyyyMMddHHMISS) return "";

      if (yyyyMMddHHMISS.length == 14) {
        newTime =
          yyyyMMddHHMISS.substring(0, 4) +
          "-" +
          yyyyMMddHHMISS.substring(4, 6) +
          "-" +
          yyyyMMddHHMISS.substring(6, 8) +
          " " +
          yyyyMMddHHMISS.substring(8, 10) +
          ":" +
          yyyyMMddHHMISS.substring(10, 12) +
          ":" +
          yyyyMMddHHMISS.substring(12, 14);
        return newTime;
      } else {
        return yyyyMMddHHMISS;
      }
    },

    HHMISS(yyyyMMddHHMISS) {
      //접수시간 포맷
      var newTime = "";
      if (!yyyyMMddHHMISS) return "";

      if (yyyyMMddHHMISS.length == 14) {
        newTime =
          yyyyMMddHHMISS.substring(8, 10) +
          ":" +
          yyyyMMddHHMISS.substring(10, 12) +
          ":" +
          yyyyMMddHHMISS.substring(12, 14);
        return newTime;
      } else {
        return yyyyMMddHHMISS;
      }
    },



    
    downloadExcel(tableId, fileNm, sheetNm){
      //number,date,time,string - n,d,t,s
        var exceltypeArr = {};
        exceltypeArr[0]          = "n";
        exceltypeArr[1]       = "t";
        exceltypeArr[2]       = "s";
        exceltypeArr[3]       = "s";
        exceltypeArr[4]       = "s";
        exceltypeArr[5]        = "s";
        exceltypeArr[6]       = "s";
        exceltypeArr[7]       = "n";
        exceltypeArr[8]       = "s";
        exceltypeArr[9]    = "s";
        exceltypeArr[10]    = "s";
        exceltypeArr[11]    = "s";
        exceltypeArr[12]    = "s";
        exceltypeArr[13]       = "s";
        exceltypeArr[14]    = "n";
        exceltypeArr[15]    = "s";
        exceltypeArr[16]    = "s";
        exceltypeArr[17]          = "s";
        exceltypeArr[18]       = "s";
        exceltypeArr[19]  = "s";
        exceltypeArr[20]  = "t";
        exceltypeArr[21]        = "s";
        exceltypeArr[22]       = "s";
        exceltypeArr[23]      = "s";
        exceltypeArr[24]      = "s";
        exceltypeArr[25]          = "s";
        
        //엑셀다운로드
        this.table_id   = tableId;
        this.file_name  = fileNm;
        this.sheet_name = sheetNm;
        this.mixin_common_exportExcel(exceltypeArr);
    },
  },
};
</script>

<style></style>
