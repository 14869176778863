<template>
    <div class="jh-dialog-wrap">
        <div class="jh-dialog-header">
            <h1>상담사조회</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="[$emit('hide')]"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">
            <div class="jh-search-form">
                <table>
                    <colgroup>
                        <col width="30px">
                        <col width="120px">
                        <col width="50px">
                        <col width="120px">
                        <col width="60px">
                        <col width="120px">
                        <col width="60px">
                        <col width="120px">
                        <col>
                    </colgroup>
                    <tr>
                        <!-- <th><label>센터</label></th>
                        <td>
                            <v-select
                            class="jh-form"
                            :items="USER_ATTR_A"
                            item-value="CD"
                            item-text="CD_NM"
                            placeholder="선택하세요"
                            v-model="USER_NEWATTR_A"
                            ></v-select>
                        </td> -->
                        <th><label>부서</label></th>
                        <td>
                            <v-select
                            class="jh-form"
                            :items="USER_ATTR_A"
                            item-value="CD"
                            item-text="CD_NM"
                            placeholder="선택하세요"
                            v-model="USER_NEWATTR_A"
                            ></v-select>
                        </td>
                        <th><label>파트</label></th>
                        <td>
                            <v-select
                            class="jh-form"
                            :items="USER_ATTR_B"
                            item-value="CD"
                            item-text="CD_NM"
                            placeholder="선택하세요"
                            v-model="USER_NEWATTR_B"
                            ></v-select>
                        </td>
                        <th><label>사번</label></th>
                        <td><v-text-field class="jh-form" name="name" v-model="USER_NEWID" @keydown.enter="selectUser"></v-text-field></td>
                        <th><label>직원명</label></th>
                        <td><v-text-field class="jh-form" name="name" v-model="USER_NEWNM" @keydown.enter="selectUser"></v-text-field></td>
                        <td class="has-search"><v-btn class="jh-btn is-search" @click="selectUser">조회</v-btn></td>
                        <!-- <td class="has-search"><v-btn class="jh-btn is-search" @click="selectUser" v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')">조회</v-btn></td> -->
                    </tr>
                </table>
            </div>
            <data-tables
            ref="dataTable"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="rowClick1"
            @dblclick:row="rowClick2"
            :paginationOptions="paginationOptions"
            ></data-tables> 
        </div>
        <div class="jh-dialog-footer">
          <v-btn class="jh-btn id-md is-main" @click="btnCheck" >확인</v-btn>
        </div>
        <!-- <template v-if="footerSubmit">
            <div class="jh-dialog-footer">
                <v-btn class="jh-btn id-md is-main" @click="$emit('submit')" >{{ footerHideTitle }}</v-btn>
            </div>
        </template> -->
    </div>
</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
  name: "MENU_P101801", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    userInfo: {
      type: Object,
      value: {},
    },
  },
  computed: {
    dataTableInstance: function() {
      return this.$refs.dataTable;
    },
    initHeaders(){
        return {
            SERVICE: "phone.ars",
            METHOD: "",
            TYPE: 'BIZ_SERVICE',
        };
    },
  },
  components: {
    dataTables
  },
  data() {
    return {
      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'ROWNUM',
          width: '50px',
          sortable: false,
        },
        { text: "사번", value: 'USER_ID', align: 'center', sortable: false, },
        { text: "직원명", value: 'USER_NM', align: 'center', sortable: false, },
        // { text: "닉네임", value: 'USER_NICK', align: 'center', sortable: false, },
        // { text: "센터", value: 'ATTR_DIV_NM_A', align: 'center', sortable: false, },
        { text: "부서", value: 'ATTR_DIV_NM_A', align: 'center', sortable: false, },
        { text: "파트", value: 'ATTR_DIV_NM_B', align: 'center', sortable: false, },
        // { text: "기타", value: 'ATTR_DIV_NM_D', align: 'center', sortable: false, },
      ],
      // gridDataText: [],
      // page: 1,
      // pageCount: 0,
      // itemsPerPage: 0,

      ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company,

      USER_ATTR_A: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      USER_ATTR_B: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      USER_ATTR_C: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      USER_ATTR_D: [
        {
          CD: '',
          CD_NM: '전체'
        },
      ],

      //ASP_NEWCUST_KEY: this.userInfo.ASP_CUST_KEY,
     // ASP_NEWCUST_KEY: "001",

      USER_NEWID: '',
      USER_NEWNM: '',
      USER_NEWNICK: '',

      USER_NEWATTR_A: '',
      USER_NEWATTR_B: '',
      USER_NEWATTR_C: '',
      USER_NEWATTR_D: '',

      READ_YN: '',

      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '380px',
        items: undefined,
        itemKey: 'ROWNUM',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        disableSort: true,
        load: this.getUserInfo
      },
      paginationOptions: {
        totalVisible: 10
      },

      userInfo: {},
    };
  },

  created() {

    this.dataTableOptions.headers = this.gridDataHeaders;
    //this.dataTableOptions.items = this.gridDataText;

    this.ASP_CUST_KEY = "001",
    this.USER_NEWID = this.userInfo?.USER_ID;
    this.USER_NEWNM = this.userInfo?.USER_NM;
    this.USER_NEWNICK = this.userInfo?.USER_NICK;

    this.USER_NEWATTR_A = this.userInfo?.USER_ATTR_A || '';
    this.USER_NEWATTR_B = this.userInfo?.USER_ATTR_B || '';
    // this.USER_NEWATTR_C = this.userInfo?.USER_ATTR_C || '';

    this.USER_ATTR_A = [{CD: '', CD_NM: '전체'}];
    this.USER_ATTR_B = [{CD: '', CD_NM: '전체'}];
    // this.USER_ATTR_C = [{CD: '', CD_NM: '전체'}];

    // this.getAttrDiv('A');
    // this.getAttrDiv('B');
    // this.getAttrDiv('C');

  },

  mounted() {
    this.getAttrList();
  },


  watch: {
    userInfo: {
      handler: function() {
        this.init();

        //this.ASP_CUST_KEY = this.$store.getters['userStore/GE_USER_ROLE'].company,
        //this.ASP_NEWCUST_KEY = this.userInfo.ASP_CUST_KEY;
        this.ASP_CUST_KEY = "001",
        this.ASP_NEWCUST_KEY = "001";
        this.USER_NEWID = this.userInfo?.USER_ID || '';
        this.USER_NEWNM = this.userInfo?.USER_NM || '';
        // this.USER_NEWNICK = this.userInfo.USER_NICK;

        this.USER_NEWATTR_A = this.userInfo?.USER_ATTR_A || '';
        this.USER_NEWATTR_B = this.userInfo?.USER_ATTR_B || '';
        // this.USER_NEWATTR_C = this.userInfo?.USER_ATTR_C || '';
        this.selectUser();
        
        this.USER_ATTR_A = [{ CD: '', CD_NM: '전체' }];
        this.USER_ATTR_B = [{ CD: '', CD_NM: '전체' }];
        this.getAttrList();
        // this.USER_ATTR_C = [{ CD: '', CD_NM: '전체' }];
        // this.USER_ATTR_D = [{ CD: '', CD_NM: '전체' }];
        // this.getAttrDiv('A');
        // this.getAttrDiv('B');
        // this.getAttrDiv('C');
        // this.getAttrDiv('D');
      },
      deep: true
    },
    "USER_NEWATTR_A": async function (val) {
      this.USER_NEWATTR_B = '';
      if(val == ''){
        this.USER_ATTR_B = [{ CD: '', CD_NM: '전체' }];
      } else {
        this.USER_ATTR_B = await this.mixin_get_attr_by_attr('', val);
      }
      
    },
  },

  methods: {
    init() {
      //this.ASP_NEWCUST_KEY = this.userInfo.ASP_CUST_KEY;
      //this.ASP_NEWCUST_KEY = "001";

      this.USER_NEWID = '';
      this.USER_NEWNM = '';
      this.USER_NEWNICK = '';

      this.USER_NEWATTR_A = '';
      this.USER_NEWATTR_B = '';
      this.USER_NEWATTR_C = '';
    },

    initCust(){
      this.userInfo.NO_CUST_KEY = 'true';
    },

    //상담사 소속 콤보박스 생성
    getAttrDiv(attr) {
      api.post("api/palette/common/get-attr-div/list",   //api url입력
      {
        ATTR_CD: attr,
        ATTR_DIV_CD: '',
      },
      {head: {
        "SERVICE" : "palette.common.get-attr-div",
        "METHOD" : "list",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        if (attr == 'A'){
          this.USER_ATTR_A = [...this.USER_ATTR_A, ...response.data.DATA];
        } else if (attr == "B") {
          this.USER_ATTR_B = [...this.USER_ATTR_B, ...response.data.DATA];
        } else if (attr == "C") {
          this.USER_ATTR_C = [...this.USER_ATTR_C, ...response.data.DATA];
        } else if (attr == "D") {
          this.USER_ATTR_D = [...this.USER_ATTR_D, ...response.data.DATA];
        }
      })
      .catch((err) => {
        alert(err);
      })
    },

    selectUser() {
      // this.dataTableOptions.load = this.getUserInfo;

      if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
        else this.dataTableInstance.loadData();
    },

    async getUserInfo(loadOptions) {

      return api.post("api/setting/agent/manage/user-info-inqire-popup/inqire",   //api url입력
        {
          //ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
          USER_ID: this.USER_NEWID,
          USER_NM: this.USER_NEWNM,
          USER_NICK: this.USER_NEWNICK,
          USER_ATTR_A: this.USER_NEWATTR_A,
          USER_ATTR_B: this.USER_NEWATTR_B,
          USER_ATTR_C: this.USER_NEWATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {
          head: {
            "SERVICE": "setting.agent.manage.user-info-inqire-popup",
            "METHOD": "inqire",
            "TYPE": "BIZ_SERVICE",
            "ROW_CNT": loadOptions.itemsPerPage,
            "PAGES_CNT": loadOptions.page,
          }
        })
        .then((response) => {
          // this.gridDataText = response.data.DATA;
          // for (var i = 0; i < this.gridDataText.length; i++) {
          //   this.gridDataText[i]["index"] = i + 1;
          // }``
          // this.page = 1;
          
          let header = response.data.HEADER;
          let data = response.data.DATA;

          return {
            data: data,
            totalCount: header.TOT_COUNT
          };
        })
        .catch((err) => {
          alert(err);
        })
    },

    //Table row 클릭이벤트
    rowClick2(e, { item }) {
        this.userInfo.USER_ID = item.USER_ID;
        this.userInfo.USER_NM = item.USER_NM;
        // this.userInfo.USER_NICK = item.USER_NICK;
        this.userInfo.USER_ATTR_A = item.USER_ATTR_A;
        this.userInfo.USER_ATTR_B = item.USER_ATTR_B;
        this.userInfo.USER_ATTR_C = item.USER_ATTR_C;

        this.$emit("selectUser", this.userInfo);
        this.init();
        this.initCust()
        this.selectUser();
    },

    //Table row 클릭이벤트
    rowClick1(item, row) {
      if (item != null) {
        this.activeRow(row, true);
      }

      this.userInfo.USER_ID = item.USER_ID;
      this.userInfo.USER_NM = item.USER_NM;
      // this.userInfo.USER_NICK = item.USER_NICK;
      this.userInfo.USER_ATTR_A = item.USER_ATTR_A;
      this.userInfo.USER_ATTR_B = item.USER_ATTR_B;
      this.userInfo.USER_ATTR_C = item.USER_ATTR_C;
    },

    activeRow(row, value) {
      console.log(row);
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    btnCheck(){
      if(typeof this.userInfo.USER_ID == 'undefined'){
        alert(`선택해주세요.`)
        return;
      }
      this.$emit("selectUser", this.userInfo);
      this.init();
      this.initCust()
      this.selectUser();
    },

    async getAttrList() {
        this.USER_ATTR_A = await this.mixin_get_attr_by_attr('****', '');
    },

  },

  
};
</script>

<style></style>
